<template>
  <div class="content">
    <div class="container_body">
      <article class="link_container_body">
        <h1>关于{{ systemName }}</h1>
        <div class="article-content">
          <h2>一、如何选择浏览器与屏幕分辨率</h2>
          <p>“{{ systemName }}”支持IE10.0以上、谷歌等浏览器，最低分辨率为1366*768，建议分辨率1920*1080</p>
          <h2>二、如何切换办事大厅</h2>
          <p>“{{ systemName }}”如需切换办事大厅，请点击网页顶部的请选择办事大厅选项 ，即可切换办事大厅。</p>
          <h2>三、如何注册</h2>
          <p>
            “{{ systemName }}”用户分为两类，一类为“个人用户”，一类为“机构用户”。
            注册时，请点击网站页面右上角“个人注册”、“机构注册”，即可进入用户注册界面。
          </p>
          <h3>(一)、个人用户注册</h3>
          <h3>1、注册</h3>
          <p>
            &nbsp;&nbsp;&nbsp;首先填写基本信息,为防止他人滥用您的手机号码，我们启用了手机验证码功能。手机号码填写后，点击右侧“获取验证码”，系统发送验证码至您的手机，将其填至“验证码”栏内。基本信息填写完成后，阅读并同意《{{
              systemName
            }}注册协议》，点击“注册”按钮，即可完成个人用户注册。
          </p>
          <h3>2、实名认证</h3>
          <h4>（1）实名认证做什么用</h4>
          <p>
            &nbsp;&nbsp;实名认证后，您即可在“{{
              systemName
            }}”中登录，直接网上办事。如您不实名认证，也可登录，但仅享有政策法规、办事指南、资料下载和留言板查看功能。
          </p>
          <h4>（2）实名认证方法</h4>
          <p>&nbsp;&nbsp;A、注册之后马上实名认证</p>
          <p>
            &nbsp;&nbsp;注册成功后，系统会提示您进行身份证和姓名认证（实名认证）。准确填写您的“姓名”、“身份证号”、“性别”、“民族”，点击“实名认证”，稍待几秒，即可认证成功。
          </p>
          <p>&nbsp;&nbsp;B、通过“个人中心”进行“实名认证”</p>
          <p>
            &nbsp;&nbsp;登录后，点击网页右上角您的用户名，进入“个人中心”，然后在“实名认证”后点击编辑，即可进入实名认证操作界面。
          </p>
          <p>&nbsp;&nbsp;C、网上办事前进行“实名认证”</p>
          <p>
            &nbsp;&nbsp;在进行网上办事时，如您尚未进行实名认证，系统会提示您先进行“实名认证”。
          </p>

          <h3>(二)、机构用户注册</h3>
          <h3>1、注册</h3>
          <p>
            &nbsp;&nbsp;&nbsp;首先填写基本信息,为防止他人滥用您的手机号码，我们启用了手机验证码功能。手机号码填写后，点击右侧“获取验证码”，系统发送验证码至您的手机，将其填至“验证码”栏内。基本信息填写完成后，阅读并同意《{{
              systemName
            }}注册协议》，点击“注册”按钮，即可完成机构用户注册。
          </p>
          <h3>2、实名认证</h3>
          <h4>（1）实名认证做什么用</h4>
          <p>
            &nbsp;&nbsp;实名认证后，您即可在“{{
              systemName
            }}”中登录，直接网上办事。如您不实名认证，也可登录，但仅享有政策法规、办事指南、资料下载和留言板查看功能。
          </p>
          <h4>（2）实名认证方法</h4>
          <p>&nbsp;&nbsp;A、注册之后马上实名认证</p>
          <p>
            &nbsp;&nbsp;注册成功后，系统会提示您进行身份证和姓名认证（实名认证）。准确填写您的“法人类型”、“企业名称”、“企业类型”、“企业工商注册号/统一社会信用代码”，“法定代表人”，“法人代表人民族”，“法定代表人身份证号”，“法定代表人性别”，点击“立即注册”，注册成功，提示前往窗口完成机构用户实名认证审核。
          </p>
          <p>&nbsp;&nbsp;B、通过“个人中心”进行“实名认证”</p>
          <p>
            &nbsp;&nbsp;登录后，点击网页右上角您的用户名，进入“个人中心”，然后在“实名认证”后点击编辑，即可进入实名认证操作界面。
          </p>
          <p>&nbsp;&nbsp;C、网上办事前进行“实名认证”</p>
          <p>
            &nbsp;&nbsp;在进行网上办事时，如您尚未进行实名认证，系统会提示您先进行“实名认证”。
          </p>
          <p>&nbsp;&nbsp;D、办事大厅注册审核</p>
          <p>
            &nbsp;&nbsp;在大厅窗口进行信息审核，审核通过后由大厅统一配发企业密钥。
          </p>

          <h2>四、怎样登录</h2>
          <p>
            点击网站页面右上角“登录”，在其下拉的两类用户中，点选其一，即可进入用户登录界面。
          </p>
          <h3>1、个人登录</h3>
          <p>&nbsp;&nbsp;正确填写“用户名/手机号”、“密码”，即可登录。</p>
          <h3>2、法人登录</h3>
          <p>
            &nbsp;&nbsp;正确填写“用户名/手机号”、“密码”，插入企业密钥，即可登录。
          </p>
          <h2>五、怎样办事</h2>
          <p>
            进入服务大厅界面，可进行业务申请与预约，另外，提供了查询与证书核实、及政策法规、办事指南与资料下载查看的功能。
          </p>
          <h2>六、怎样咨询投诉</h2>
          <p>我们提供了留言板功能咨询投诉方式</p>
          <h3>1、我要留言</h3>
          <p>
            &nbsp;&nbsp;通过我要留言，通过选择留言机构，可对业务办理方式进行咨询和提出意见与投诉。
          </p>
          <h3>2、我的留言</h3>
          <p>&nbsp;&nbsp;通过我的留言，可查看留言机构给予的回复。</p>
          <h2>七、其他常见问题</h2>
          <h3>1、网站安全需要注意什么？</h3>
          <p>
            &nbsp;&nbsp;使用本网站时，为了保证您的资料安全及使用方便，请尽量遵守以下规则：
          </p>
          <p>（1）尽量避免在公共场合（如网吧等）上网登录办事系统。</p>
          <p>（2）不管您在任何场合上网登录查询系统，离开时一定要关闭浏览器。</p>
          <h3>2、机构用户登录需要注意什么？</h3>
          <p>
            &nbsp;&nbsp;机构用户需要使用企业密钥登录，网站提供插件，下载安装后，即可正常登录。
          </p>
          <h3>3、机构用户拍照功能需要注意什么？</h3>
          <p>
            &nbsp;&nbsp;机构用户使用上传附件需要使用高拍仪时，网站提供插件，下载安装后，可以正常使用。
          </p>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      systemName: window.CONFIG.APP_INFO.systemName
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/link.scss';
</style>
